<template>
  <div class="mt-3">
    <div v-if="isBynderAkeneoAssetJob || isBynderAkeneoRefEntityJob || isBynderAkeneoCategoryJob">
      <h5>{{ $t('job.jobInstance.bynderAkeneo.config.bynder_configuration.title') }}</h5>
      <ConnectionSelectField
          class="w-75 mb-3"
          id="bynderConnectionFilter"
          type="bynder"
          :label="$t('job.jobInstance.bynderAkeneo.config.bynder_connection')"
          :onUpdate="(v) => {this.bynderConnection = v}"
          :baseValue="this.bynderConnection"
          :company-code="this.jobInstance.companyCode"
          :error="this.getPropertyErrors(['bynder_to_akeneo_ref_entity_sync', 'bynder_connection'])"
      />
    </div>
    <h5>{{ $t('job.jobInstance.bynderAkeneo.config.akeneo_configuration.title') }}</h5>
    <ConnectionSelectField
        class="w-75 mb-3"
        id="akeneoConnectionFilter"
        type="akeneo"
        :label="$t('job.jobInstance.bynderAkeneo.config.akeneo_connection')"
        :onUpdate="(v) => {this.akeneoConnection = v}"
        :baseValue="this.akeneoConnection"
        :company-code="this.jobInstance.companyCode"
        :error="this.getPropertyErrors(['check_akeneo_config', 'akeneo_connection'])"
    />
    <h5>{{ $t('job.jobInstance.bynderAkeneo.config.general.title') }}</h5>
    <ToggleField
        class="w-75"
        id="delta"
        :label="$t('job.jobInstance.bynderAkeneo.config.general.delta')"
        v-model="delta"
        :error="this.getPropertyErrors(['bynder_to_akeneo_asset_sync', 'delta'])"
    />
    <DateTimeField
        v-if="delta"
        id="deltaDate"
        :label="$t('job.jobInstance.bynderAkeneo.config.akeneo_configuration.deltaDate')"
        v-model="deltaDate"
        :format="'YYYY-MM-DD[T]HH:mm:ss[Z]'"
        :error="this.getPropertyErrors(['bynder_to_akeneo_asset_sync', 'deltaDate'])"
    />
    <div v-if="isBynderAkeneoAssetJob || isBynderAkeneoRefEntityJob || isBynderAkeneoCategoryJob ">
      <h5>{{ $t('job.jobInstance.bynderAkeneo.config.mappings.title') }}</h5>
      <p class="w-75">{{ $t('job.jobInstance.bynderAkeneo.config.mappings.description') }}</p>
      <MappingFields
          id="mappings"
          v-model="mappings"
          :is-asset-mapping="isBynderAkeneoAssetJob"
          :is-category-mapping="isBynderAkeneoCategoryJob"
          :is-reference-entity-mapping="isBynderAkeneoRefEntityJob"
          :errors="this.getPropertyErrors(['bynder_to_akeneo_asset_sync', 'mapping'])"
      />
    </div>
    <div v-if="isBynderAkeneoAssetJob || isAkeneoMultibindingJob">
      <h5>{{ $t('job.jobInstance.bynderAkeneo.config.multibindings.title') }}</h5>
      <p class="w-75">{{ $t('job.jobInstance.bynderAkeneo.config.multibindings.description') }}</p>
      <MultibindingFields
          id="multibindings"
          v-model="multibindings"
          :errors="this.getPropertyErrors(['akeneo_to_akeneo_asset_product_multibinding', 'akeneo_asset_multi_products'])"
      />
    </div>
    <div v-if="isBynderAkeneoAssetJob">
      <h5>{{ $t('job.jobInstance.bynderAkeneo.config.reverse_mapping.title') }}</h5>
      <p class="w-75">{{ $t('job.jobInstance.bynderAkeneo.config.reverse_mapping.description') }}</p>
      <ReverseMappingFields
          id="reverseMappings"
          v-model="reverseMapping"
          :errors="this.getPropertyErrors(['akeneo_product_value_to_bynder_media_sync', 'reverseMapping'])"
      />
    </div>
    <h5 v-if="!this.isBynderAkeneoCategoryJob">{{ $t('job.jobInstance.form.tab.advanced_settings') }}</h5>
    <ToggleField
        v-if="isBynderAkeneoAssetJob"
        class="w-75"
        id="suffixAssetCodeWithFamilyCode"
        :label="$t('job.jobInstance.bynderAkeneo.config.general.suffixAssetCodeWithFamilyCode')"
        v-model="suffixAssetCodeWithFamilyCode"
        :error="this.getPropertyErrors(['bynder_to_akeneo_asset_sync', 'suffixAssetCodeWithFamilyCode'])"
    />
    <NumberField
        id="assetWriterBatchSize"
        class="w-75"
        v-if="isBynderAkeneoAssetJob"
        :label="$t('job.jobInstance.bynderAkeneo.config.akeneo_configuration.asset_writer_batch_size')"
        v-model="assetWriterBatchSize"
        :error="this.getPropertyErrors(['bynder_to_akeneo_asset_sync', 'assetWriterBatchSize'])"
        :tooltip="$t('job.jobInstance.bynderAkeneo.config.akeneo_configuration.batch_size_input_number')"
    />
    <NumberField
        id="refEntityRecordWriterBatchSize"
        class="w-75"
        v-if="isBynderAkeneoRefEntityJob"
        :label="$t('job.jobInstance.bynderAkeneo.config.akeneo_configuration.asset_writer_batch_size')"
        v-model="refEntityRecordWriterBatchSize"
        :error="this.getPropertyErrors(['bynder_to_akeneo_ref_entity_sync', 'refEntityRecordWriterBatchSize'])"
        :tooltip="$t('job.jobInstance.bynderAkeneo.config.akeneo_configuration.batch_size_input_number')"
    />
    <NumberField
        id="productWriterBatchSize"
        class="w-75"
        v-if="isBynderAkeneoAssetJob || isAkeneoMultibindingJob"
        :label="$t('job.jobInstance.bynderAkeneo.config.akeneo_configuration.product_writer_batch_size')"
        v-model="productWriterBatchSize"
        :error="this.getPropertyErrors(['akeneo_to_akeneo_asset_product_multibinding', 'productWriterBatchSize'])"
        :tooltip="$t('job.jobInstance.bynderAkeneo.config.akeneo_configuration.batch_size_input_number')"
    />
  </div>
</template>

<script>
import {useToast} from "vue-toastification";
import ToggleField from "@/components/Form/ToggleField";
import NumberField from "@/components/Form/NumberField";
import DateTimeField from "@/components/Form/DateTimeField";
import jobConfigurationMixin from "@/mixins/jobConfigurationMixin";
import MappingFields from "@/components/Job/JobInstance/Configuration/BynderAkeneo/Mapping/MappingFields";
import MultibindingFields
  from "@/components/Job/JobInstance/Configuration/BynderAkeneo/Multibinding/MultibindingFields";
import ReverseMappingFields
  from "@/components/Job/JobInstance/Configuration/BynderAkeneo/ReverseMapping/ReverseMappingFields";
import ConnectionSelectField from "@/views/Connection/ConnectionSelectField";

export default {
  name: "ConfigurationForm",
  mixins: [jobConfigurationMixin],
  components: {
    ReverseMappingFields,
    MultibindingFields,
    MappingFields,
    DateTimeField,
    ConnectionSelectField,
    ToggleField,
    NumberField,
  },
  props: {
    confInputKey: Number,
    modelValue: Object,
    errors: Object,
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      jobInstance: this.modelValue,
      configuration: JSON.parse(this.modelValue.configuration),
    }
  },
  computed: {
    isBynderAkeneoAssetJob () {
      return this.jobInstance.jobCode === "bynder_media_to_akeneo_asset_sync";
    },
    isBynderAkeneoCategoryJob () {
      return this.jobInstance.jobCode === "bynder_media_to_akeneo_category_sync";
    },
    isBynderAkeneoRefEntityJob () {
      return this.jobInstance.jobCode === "bynder_media_to_akeneo_ref_entity_sync";
    },
    isAkeneoMultibindingJob () {
      return this.jobInstance.jobCode === "akeneo_to_akeneo_asset_product_multibinding";
    },
    bynderConnection: {
      get () {
        return this.getJobConfiguration(["bynder_connection"]);
      },
      set (v) {
        this.setJobConfiguration(["bynder_connection"], v)
      },
    },
    akeneoConnection: {
      get () {
        return this.getJobConfiguration(["akeneo_connection"]);
      },
      set (v) {
        this.setJobConfiguration(["akeneo_connection"], v)
      },
    },
    delta: {
      get () {
        return !!this.getJobConfiguration(["delta"]);
      },
      set (v) {
        this.setJobConfiguration(["delta"], v)
      },
    },
    deltaDate: {
      get () {
        return this.getJobConfiguration(["deltaDate"]);
      },
      set (v) {
        if (this.getJobConfiguration(["delta"]) && v !== "Invalid date") {
          this.setJobConfiguration(["deltaDate"], v)
        } else {
          this.setJobConfiguration(["deltaDate"], null)
        }
      },
    },
    assetWriterBatchSize: {
      get () {
        return parseFloat(this.getJobConfiguration(["assetWriterBatchSize"]) ?? '50');
      },
      set (v) {
        this.setJobConfiguration(["assetWriterBatchSize"], parseFloat(v))
      },
    },
    refEntityRecordWriterBatchSize: {
      get () {
        return parseFloat(this.getJobConfiguration(["refEntityRecordWriterBatchSize"]) ?? '50');
      },
      set (v) {
        this.setJobConfiguration(["refEntityRecordWriterBatchSize"], parseFloat(v))
      },
    },
    productWriterBatchSize: {
      get () {
        return parseFloat(this.getJobConfiguration(["productWriterBatchSize"]) ?? '50');
      },
      set (v) {
        this.setJobConfiguration(["productWriterBatchSize"], parseFloat(v))
      },
    },
    suffixAssetCodeWithFamilyCode: {
      get () {
        return !!this.getJobConfiguration(["suffixAssetCodeWithFamilyCode"]);
      },
      set (v) {
        this.setJobConfiguration(["suffixAssetCodeWithFamilyCode"], v)
      },
    },
    mappings: {
      get () {
        return this.getJobConfiguration(["mapping"], []);
      },
      set (v) {
        this.setJobConfiguration(["mapping"], v)
      },
    },
    multibindings: {
      get () {
        return this.getJobConfiguration(["akeneo_asset_multi_products"], []);
      },
      set (v) {
        this.setJobConfiguration(["akeneo_asset_multi_products"], v)
      },
    },
    reverseMapping: {
      get () {
        return this.getJobConfiguration(["reverseMapping"], []);
      },
      set (v) {
        this.setJobConfiguration(["reverseMapping"], v)
      },
    },
  },
  methods: {}
}
</script>

<style scoped lang="scss">
</style>
