<template>
  <component
      :is="configurationFormName"
      v-model="jobInstance"
      :confInputKey="confInputKey"
      :errors="errors"
      @update:modelValue="emitUpdate"
  />
</template>

<script>
import JsonConfigurationForm from "../Configuration/JsonConfigurationForm";
import ImatagConfigurationForm from "./BynderImatag/ImatagConfigurationForm";
import ShopifyConfigurationForm from "./BynderShopify/ShopifyConfigurationForm";
import BynderAkeneoConfigurationForm from "./BynderAkeneo/BynderAkeneoConfigurationForm";
import DeleteBynderAkeneoConfigurationForm
  from "@/components/Job/JobInstance/Configuration/BynderAkeneo/DeleteBynderAkeneoConfigurationForm.vue";
import BynderRulesConfigurationForm from "./Bynder/BynderRulesConfigurationForm";
import AkeneoBynderConfigurationForm
  from "@/components/Job/JobInstance/Configuration/BynderAkeneo/AkeneoBynderConfigurationForm.vue";

export default {
  name: "ConfigurationForm",
  components: {
    JsonConfigurationForm,
    ImatagConfigurationForm,
    ShopifyConfigurationForm,
    BynderAkeneoConfigurationForm,
    AkeneoBynderConfigurationForm,
    DeleteBynderAkeneoConfigurationForm,
    BynderRulesConfigurationForm,
  },
  props: {
    modelValue: Object,
    errors: Object,
  },
  data () {
    return {
      jobInstance: this.modelValue,
      confInputKey: Math.random()
    }
  },
  methods: {
    refreshComponent() {
      this.confInputKey = Math.random()
    },
    emitUpdate(value) {
      this.$emit(`update:modelValue`, value);
    },
  },
  computed: {
    configurationFormName () {
      if(this.jobInstance.showJsonConfiguration === true) {
        return JsonConfigurationForm;
      }

      switch (this.modelValue.jobCode) {
        case "bynder_imatag_watermark":
          return ImatagConfigurationForm
        case "bynder_media_to_akeneo_asset_sync":
        case "bynder_media_to_akeneo_ref_entity_sync":
        case "bynder_media_to_akeneo_category_sync":
        case "akeneo_to_akeneo_asset_product_multibinding":
          return BynderAkeneoConfigurationForm
        case "akeneo_product_to_bynder_property":
          return AkeneoBynderConfigurationForm
        case "bynder_to_akeneo_delete_assets":
          return DeleteBynderAkeneoConfigurationForm
        case "bynder_media_to_shopify_product_image_sync":
          return ShopifyConfigurationForm
        case 'bynder_rules':
          return BynderRulesConfigurationForm
        default:
          return JsonConfigurationForm;
      }
    },
  },
}
</script>
