<template>
  <div class="card">
    <div class="mapping-summary">
      <div class="card-body" @click="openModal">
        <h5 class="card-title">
          {{ attribute }} -> {{ bynder_property }}
        </h5>
        <div v-if="formattedMessages && formattedMessages.length > 0" class="invalid-feedback d-inline-block">
          <div v-for="(formattedMessage, index) in this.formattedMessages" :key="index">
            <p>{{ formattedMessage }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
         data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t('job.jobInstance.akeneoBynder.config.mapping.title') }}
            </h5>
          </div>
          <div class="modal-body">
            <TextField
                class=""
                id="attribute"
                :required=true
                :error="getErrorMessage('attribute')"
                :label="$t('form.akeneoBynder.mapping.attribute')"
                v-model="attribute"
            />
            <TextField
                class=""
                id="bynder_property"
                :required=true
                :error="getErrorMessage('bynder_property')"
                :label="$t('form.akeneoBynder.mapping.bynder_property')"
                v-model="bynder_property"
            />
            <TextField
                class=""
                id="channel"
                :required=false
                :tooltip="$t('form.akeneoBynder.mapping.channel.tooltip')"
                :tooltip-placement="'bottom'"
                :error="getErrorMessage('channel')"
                :label="$t('form.akeneoBynder.mapping.channel')"
                v-model="channel"
            />
            <TextField
                class=""
                id="locale"
                :required=false
                :tooltip="$t('form.akeneoBynder.mapping.locale.tooltip')"
                :tooltip-placement="'bottom'"
                :error="getErrorMessage('locale')"
                :label="$t('form.akeneoBynder.mapping.locale')"
                v-model="locale"
            />
            <TextField
                class=""
                id="default_label_locale"
                :required=false
                :tooltip="$t('form.akeneoBynder.mapping.default_label_locale.tooltip')"
                :tooltip-placement="'bottom'"
                :error="getErrorMessage('default_label_locale')"
                :label="$t('form.akeneoBynder.mapping.default_label_locale')"
                v-model="default_label_locale"
            />
            <TextField
                class=""
                id="reference_entity_attribute"
                :required=false
                :tooltip="$t('form.akeneoBynder.mapping.reference_entity_attribute.tooltip')"
                :tooltip-placement="'bottom'"
                :error="getErrorMessage('reference_entity_attribute')"
                :label="$t('form.akeneoBynder.mapping.reference_entity_attribute')"
                v-model="reference_entity_attribute"
            />
          </div>
          <div class="modal-footer">
            <div v-if="formattedMessages && formattedMessages.length > 0" class="invalid-feedback d-inline-block">
              <div v-for="(formattedMessage, index) in this.formattedMessages" :key="index">
                <p>{{ formattedMessage }}</p>
              </div>
            </div>
            <button @click="removeMapping" class="mt-3 btn btn-sm btn-danger">
              <i class="bi bi-trash2" aria-hidden="true"/>
              {{ $t('common.remove') }}
            </button>
            <button @click="saveMapping" class="mt-3 btn btn-sm btn-success">
              <i class="bi bi-check" aria-hidden="true"/>
              {{ $t('common.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap'
import TextField from "@/components/Form/TextField";

export default {
  name: "MappingField",
  components: {
    TextField,
  },
  props: {
    id: String,
    mapping: Object,
    errors: Object,
  },
  data () {
    return {
      modal: null,
      attribute: this.mapping.attribute,
      bynder_property: this.mapping.bynder_property,
      locale: this.mapping.locale,
      channel: this.mapping.channel,
      reference_entity_attribute: this.mapping.reference_entity_attribute,
      default_label_locale: this.mapping.default_label_locale,
      formErrors: {},
    }
  },
  computed: {
    formattedMessages () {
      let formattedMessages = [];
      if (this.errors && Object.keys(this.errors).length !== 0) {
        for (const key in this.errors) {
          formattedMessages.push(`${key}: ${this.errors[key]}.`);
        }
      }

      if (Object.keys(this.formErrors).length !== 0) {
        for (const key in this.formErrors) {
          formattedMessages.push(`${key}: ${this.formErrors[key]}.`);
        }
      }

      return formattedMessages;
    },
  },
  methods: {
    openModal () {
      this.modal.show();
    },
    saveMapping (e) {
      e.preventDefault();

      this.formErrors          = {};
      const requiredProperties = ['attribute', 'bynder_property'];
      for (const requiredProperty of requiredProperties) {
        if (!this[requiredProperty] || this[requiredProperty] === "") {
          this.formErrors[requiredProperty] = this.$t('common.isRequired');
        }
      }

      if (Object.keys(this.formErrors).length > 0) {
        return;
      }

      this.modal.hide();

      let conf = {
        attribute: this.attribute,
        bynder_property: this.bynder_property,
      };

      const propertiesToAdd = [
        "locale",
        "channel",
        "default_label_locale",
        "reference_entity_attribute",
      ];

      for (const prop of propertiesToAdd) {
        this.addIfDefinedAndNotEmpty(conf, prop, this[prop]);
      }

      this.$emit('mapping-save', conf);
    },
    removeMapping (e) {
      if (confirm(this.$t('form.akeneoBynder.mapping.delete_confirmation'))) {
        this.modal.hide();
        this.$emit('mapping-remove');
      }
      e.preventDefault();
    },
    getErrorMessage (field) {
      if (this.errors && this.errors[field]) {
        return this.errors[field];
      }
      if (this.formErrors && this.formErrors[field]) {
        return this.formErrors[field];
      }

      return null;
    },
    addIfDefinedAndNotEmpty (obj, propName, propValue) {
      if (propValue !== undefined && propValue !== null && propValue !== "") {
        obj[propName] = propValue;
      }
    }
  },
  mounted () {
    this.modal = new Modal(this.$refs.exampleModal)
    if (this.attribute === "" || this.bynder_property === "") {
      this.modal.show();
    }
  },
}
</script>

<style lang="scss">

.mapping-summary {
  cursor: pointer;

  .card-body {
    min-height: 120px;
  }

  :hover {
    background-color: lightgray;
  }
}

</style>
