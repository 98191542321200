<template>
  <div class="d-flex">
    <div class="me-auto">
      <label :class="'form-label toggle-label ' + labelClass">
        {{ label }}
      </label>
    </div>
    <div>
      <Toggle
          v-model="toggleValue"
          @change="emitUpdate"
          on-label="Yes"
          off-label="No"
      />
    </div>
  </div>
</template>

<script>
import Toggle from '@vueform/toggle'

export default {
  name: "ToggleField",
  components: {Toggle},
  props: {
    modelValue: Boolean,
    label: String,
    labelClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      toggleValue: this.modelValue
    }
  },
  methods: {
    emitUpdate(value) {
      this.$emit(`update:modelValue`, value);
    }
  }
}
</script>

<style scoped>

</style>
