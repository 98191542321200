<template>
  <div class="mt-3">
    <h5>{{ $t('job.jobInstance.akeneoBynder.config.bynder_configuration.title') }}</h5>
    <ConnectionSelectField
        class="w-75 mb-3"
        id="bynderConnectionFilter"
        type="bynder"
        :label="$t('job.jobInstance.akeneoBynder.config.bynder_connection')"
        :onUpdate="(v) => {this.bynderConnection = v}"
        :baseValue="this.bynderConnection"
        :company-code="this.jobInstance.companyCode"
        :error="this.getPropertyErrors(['check_bynder_config', 'bynder_connection'])"
    />
  </div>
  <h5>{{ $t('job.jobInstance.akeneoBynder.config.akeneo_configuration.title') }}</h5>
  <ConnectionSelectField
      class="w-75 mb-3"
      id="akeneoConnectionFilter"
      type="akeneo"
      :label="$t('job.jobInstance.akeneoBynder.config.akeneo_connection')"
      :onUpdate="(v) => {this.akeneoConnection = v}"
      :baseValue="this.akeneoConnection"
      :company-code="this.jobInstance.companyCode"
      :error="this.getPropertyErrors(['check_akeneo_config', 'akeneo_connection'])"
  />
  <h5>{{ $t('job.jobInstance.akeneoBynder.config.general.title') }}</h5>
  <ToggleField
      class="w-75"
      id="delta"
      :label="$t('job.jobInstance.akeneoBynder.config.general.delta')"
      v-model="delta"
      :error="this.getPropertyErrors(['akeneo_product_to_bynder_property', 'delta'])"
  />
  <DateTimeField
      v-if="delta"
      id="deltaDate"
      :label="$t('job.jobInstance.akeneoBynder.config.general.deltaDate')"
      v-model="deltaDate"
      :format="'YYYY-MM-DD[T]HH:mm:ss[Z]'"
      :error="this.getPropertyErrors(['akeneo_product_to_bynder_property', 'deltaDate'])"
  />
  <hr class="my-3 w-75" />
  <div class="my-2">
    <h5>{{ $t('job.jobInstance.akeneoBynder.config.product.title') }}</h5>
    <ProductFilterField
        id="productSearch"
        v-model="productSearch"
        :errors="this.getPropertyErrors(['akeneo_product_to_bynder_property', 'product', 'search'])"
        class="mb-3"
    />
    <ProductIdentifierToBynderOptionField
        id="productIdentifierMapping"
        v-model="productIdentifierMapping"
        :errors="this.getPropertyErrors(['akeneo_product_to_bynder_property', 'product', 'identifier'])"
        class="mb-3"
    />
    <div class="fs-6 fw-bold">{{ $t('job.jobInstance.akeneoBynder.config.product.values.description') }}</div>
    <ProductValueToBynderOptionFields
        id="productValuesMappings"
        v-model="productValuesMappings"
        :errors="this.getPropertyErrors(['akeneo_product_to_bynder_property', 'product', 'values'])"
        class="mb-3"
    />
  </div>
  <hr class="my-3 w-75" />
  <div class="my-2">
    <h5>{{ $t('job.jobInstance.akeneoBynder.config.product_model.title') }}</h5>
    <ProductIdentifierToBynderOptionField
        id="productModelIdentifierMapping"
        v-model="productModelIdentifierMapping"
        :type="'product_model'"
        :errors="this.getPropertyErrors(['akeneo_product_to_bynder_property', 'product_model', 'identifier'])"
        class="mb-3"
    />
    <div class="fs-6 fw-bold">{{ $t('job.jobInstance.akeneoBynder.config.product_model.values.description') }}</div>
    <ProductValueToBynderOptionFields
        id="productModelValuesMappings"
        v-model="productModelValuesMappings"
        :errors="this.getPropertyErrors(['akeneo_product_to_bynder_property', 'product_model', 'values'])"
        class="mb-3"
    />
    <ProductFilterField
        id="productModelSearch"
        v-model="productModelSearch"
        :errors="this.getPropertyErrors(['akeneo_product_to_bynder_property', 'product_model', 'search'])"
        class="mb-3"
    />
  </div>
</template>

<script>
import {useToast} from "vue-toastification";
import jobConfigurationMixin from "@/mixins/jobConfigurationMixin";
import ConnectionSelectField from "@/views/Connection/ConnectionSelectField";
import DateTimeField from "@/components/Form/DateTimeField.vue";
import ToggleField from "@/components/Form/ToggleField.vue";
import ProductValueToBynderOptionFields
  from "@/components/Job/JobInstance/Configuration/BynderAkeneo/ProductValueToBynderOption/ProductValueToBynderOptionFields.vue";
import ProductIdentifierToBynderOptionField
  from "@/components/Job/JobInstance/Configuration/BynderAkeneo/ProductValueToBynderOption/ProductIdentifierToBynderOptionField.vue";
import ProductFilterField
  from "@/components/Job/JobInstance/Configuration/BynderAkeneo/ProductValueToBynderOption/ProductFilterField.vue";

export default {
  name: "AkeneoBynderConfigurationForm",
  mixins: [jobConfigurationMixin],
  components: {
    ProductFilterField,
    ProductIdentifierToBynderOptionField,
    ProductValueToBynderOptionFields,
    ToggleField, DateTimeField,
    ConnectionSelectField,
  },
  props: {
    confInputKey: Number,
    modelValue: Object,
    errors: Object,
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      jobInstance: this.modelValue,
      configuration: JSON.parse(this.modelValue.configuration),
    }
  },
  computed: {
    bynderConnection: {
      get () {
        return this.getJobConfiguration(["bynder_connection"]);
      },
      set (v) {
        this.setJobConfiguration(["bynder_connection"], v)
      },
    },
    akeneoConnection: {
      get () {
        return this.getJobConfiguration(["akeneo_connection"]);
      },
      set (v) {
        this.setJobConfiguration(["akeneo_connection"], v)
      },
    },
    delta: {
      get () {
        return !!this.getJobConfiguration(["delta"]);
      },
      set (v) {
        this.setJobConfiguration(["delta"], v)
      },
    },
    deltaDate: {
      get () {
        return this.getJobConfiguration(["deltaDate"]);
      },
      set (v) {
        if (this.getJobConfiguration(["delta"]) && v !== "Invalid date") {
          this.setJobConfiguration(["deltaDate"], v)
        } else {
          this.setJobConfiguration(["deltaDate"], null)
        }
      },
    },
    productLabelAttribute: {
      get () {
        return this.getJobConfiguration(["product", "label_attribute"], []);
      },
      set (v) {
        this.setJobConfiguration(["product", "label_attribute"], v)
      },
    },

    productIdentifierMapping: {
      get () {
        return this.getJobConfiguration(["product", "identifier"], {});
      },
      set (v) {
        this.setJobConfiguration(["product", "identifier"], v)
      },
    },
    productValuesMappings: {
      get () {
        return this.getJobConfiguration(["product", "values"], []);
      },
      set (v) {
        this.setJobConfiguration(["product", "values"], v)
      },
    },
    productSearch: {
      get () {
        return this.getJobConfiguration(["product", "search"], {});
      },
      set (v) {
        this.setJobConfiguration(["product", "search"], v)
      },
    },
    productModelIdentifierMapping: {
      get () {
        return this.getJobConfiguration(["product_model", "identifier"], {});
      },
      set (v) {
        this.setJobConfiguration(["product_model", "identifier"], v)
      },
    },
    productModelValuesMappings: {
      get () {
        return this.getJobConfiguration(["product_model", "values"], []);
      },
      set (v) {
        this.setJobConfiguration(["product_model", "values"], v)
      },
    },
    productModelSearch: {
      get () {
        return this.getJobConfiguration(["product_model", "search"], {});
      },
      set (v) {
        this.setJobConfiguration(["product_model", "search"], v)
      },
    },
  },
  methods: {}
}
</script>

<style scoped lang="scss">
</style>
