<template>
  <JsonField
      id="configuration"
      :label="$t('common.property.configuration')"
      v-model="jobInstance.configuration"
      :errors="errors"
      :modes="['code']"
      :key="confInputKey"
      @update:modelValue="onJsonChange"
  />
  <div class="mt-3">
    <h5>{{ $t('job.jobInstance.property.secured_variables') }}</h5>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Code</th>
        <th scope="col">Value</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <SecuredVariableFormRow
          v-for="variable in jobInstance.securedVariables"
          :key="variable.id"
          :variable="variable"
          :job-instance="jobInstance"
      />
      </tbody>
    </table>
    <div class="d-flex flex-row-reverse">
      <button v-if="jobInstance.id" class="btn btn-primary btn-sm me-2"
              @click.prevent="addSecuredVariable">
        {{ $t('job.jobInstance.form.secured_variable.add') }}
      </button>
    </div>
  </div>
</template>

<script>
import {useToast} from "vue-toastification";
import JsonField from "../../../Form/JsonField";
import SecuredVariableFormRow from "./SecuredVariableFormRow";

export default {
  name: "ConfigurationForm",
  components: {
    JsonField,
    SecuredVariableFormRow,
  },
  props: {
    modelValue: Object,
    confInputKey: Number,
    errors: Object,
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  data () {
    return {
      jobInstance: this.modelValue
    }
  },
  methods: {
    onJsonChange(value) {
      this.jsonError = null;
      this.jobInstance.configuration = value;
      this.$emit(`update:modelValue`, this.jobInstance);
    },
    addSecuredVariable () {
      if (!Array.isArray(this.jobInstance.securedVariables)) {
        this.jobInstance.securedVariables = [];
      }

      this.jobInstance.securedVariables.push(
          {
            code: '',
            value: '',
          }
      )
    },
  }
}
</script>

<style scoped lang="scss">
</style>
